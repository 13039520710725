import { extendTheme } from "@chakra-ui/react";

import { createBreakpoints } from "@chakra-ui/theme-tools"

import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

//param initialization
const breakpoints = createBreakpoints({
  sm : "320px",
  md : "768px",
  lg : "991px",
  xs : "1024",
  xl : "1300px",
  xxl: "1755px"
} );

export default extendTheme({
  components: {
    Steps
  },
  styles: {
    global: (props) => ({
      body: {
        color: "#ffffff",
        fontFamily: "GothamPro !important",
        bg: "#010000",
        bgSize: "contain",
        bgRepeat: "no-repeat",
        lineHeight: "base"
      },
      h1: {
        fontFamily: "GothamPro Bold"
      },
      h2: {
        fontFamily: "GothamPro Bold"
      },
      h4: {
        fontFamily: "GothamPro Bold"
      }
    })
  },
  breakpoints
} );