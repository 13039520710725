import React, { useState } from "react";

import { useSpring, animated, config } from 'react-spring'

import {
  Flex,
  VStack,
  Stack,
  Heading,
  Center,
  Box,
  SimpleGrid,
  Wrap,
  WrapItem,
  Badge,
  Link,
  Image,
  Text
} from "@chakra-ui/react";

import { SRLWrapper } from "simple-react-lightbox";

import { anniversary_team } from "../../store/anniversary";

const Team = ( { thumbnail, ...rest } : any  ) => {

    const [hover, setHover] = useState(false);
    const hoverState = useSpring({
        transform: hover
            ? 'translate3d(0px, -12px, 0px) scale(1.03)'
            : 'translate3d(0px, 0px, 0px) scale(1)',
        config: config.wobbly
    });

    return (

        <Box
            align="center"
            margin="0px auto"
            pos="relative"
            p="15px"
            border="2px solid rgb(0, 19, 38)"
            borderRadius="15px"
            boxShadow="#000000 3px 3px;"
            {...rest}
        >

            <animated.div
                style={{
                    width: '100%',
                    zIndex: 1,
                    position: 'relative',
                    ...hoverState
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >

                <Link to={thumbnail} className="element_with_overlay">

                    <Image
                        src={thumbnail}
                        borderRadius="15px"
                        opacity="0.5"
                        _hover={{
                            opacity: "1"
                        }}
                    />

                </Link>

            </animated.div>

        </Box>
    )

};


export default function Anniversary() {

  return (
    <>

        <Flex
            id="anniversary"
            w="100%"
            bg="URL('./images/anniversary.png')"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPosition="bottom left"
            pos="relative"
            overflow="hidden"
        >

            <Box
                maxW={{ xl: "1200px" }}
                w="100%"
                m="0px auto"
                pos="relative"
                p={{ xxl: "550px 0px", xl: "186px 0px 216px", xs: "186px 0px 216px", lg: "15px 0px 15px", md: "15px 0px 15px", sm: "15px 0px 15px" }}
            >

                <VStack>

                    <Box
                        pos="absolute"
                        top="-15px"
                        right={{ xxl: "15px", xl: "85px", xs: "50%", lg: "50%", md: "0px", sm: "0px" }}
                        maxW="550px"
                        mr={{ xxl: "0px", xl: "0px", xs: "-275px", lg: "-275px", md: "0px", sm: "0px" }}
                        display={{xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "none", sm: "none" }}
                    >

                        <SRLWrapper options={{
                            settings: {
                                overlayColor: "#0493d7cc"
                            },
                            buttons: {
                                backgroundColor: "#0093d7",
                                iconColor: "#ffffff",
                            },
                            caption: {
                                captionColor: "#ffffff",
                                showCaption: true
                            }
                        }} >

                            <SimpleGrid
                                columns={{ xxl: 3, xl: 3, xs: 3, lg: 3, md: 3, sm: 3 }}
                                spacing={{ xxl: "20px", xl: "20px", xs: "20px", lg: "20px", md: "20px", sm: "20px" }}
                                alignItems="center"
                                pos="relative"
                                w="100%"
                                bottom="-110px"
                                zIndex="0"
                            >

                                { anniversary_team.map( ( team: any, step: any ) => (

                                    <Team
                                        thumbnail={team.thumbnail}
                                    />

                                ) ) }

                            </SimpleGrid>

                        </SRLWrapper>

                    </Box>

                    <Text
                        pos={{ xxl: "absolute", xl: "relative", xs: "relative", lg: "relative", md: "relative", sm: "relative" }}
                        top={{ xxl: "250px", xl: "80px", xs: "80px", lg: "35px", md: "35px", sm: "35px" }}
                        right={{ xxl: "0px", xl: "-250px", xs: "0px", lg: "0px", md: "0px", sm: "0px" }}
                        w={{ xxl: "565px", xl: "565px", xs: "565px", lg: "565px", md: "565px", sm: "350px" }}
                        color="#b1b1b1"
                        p="40px"
                        borderRadius="50px"
                        lineHeight="1.7"
                        boxShadow="inset 1px 0px 14px #000000"
                        _hover={{
                            bg   : "#032640",
                            color:"#ffffff"
                        }}
                    >
                        Дорогие коллеги, хочется от души поблагодарить вас за годы совместной работы, за дружбу и приятное общение, за понимание и поддержку, за яркие моменты и гениальные идеи, за единодушие и коллективную силу.
                        <br />
                        <br />
                        Хочется выразить признательность за преданную работу и за тот бесценный опыт, который мы с вами обрели.
                        <br />
                        <br />
                        Спасибо за те сложные и опасные моменты нашей жизни и вашу неоценимую помощь в развитии компании.
                        <br />
                        <br />
                        Благодарю всех, и тех, кого нет с нами и тех, кто продолжает шагать с нами и по сей день и продолжает борьбу за рост и процветание компании.
                        <br />
                        <br />
                        От имени компании хотим пожелать всем нам всего самого доброго и хорошего, но, главное — здоровья, уверенности, сил, упорства, больших возможностей, удачи, любви и достатка.
                        <br />
                        <br />
                        Спасибо вам, друзья.
                        <br />
                        Ефремов А.В.
                    </Text>

                </VStack>

            </Box>

        </Flex>

    </>
  );
}
