import React from "react";

import {
  BsAwardFill
} from "react-icons/bs";

import {
  Icon,
  Flex,
  VStack,
  Heading,
  Box,
  SimpleGrid,
  Wrap,
  WrapItem,
  Image,
  Badge
} from "@chakra-ui/react"

import { partners } from "../../store/partners";

import Move from "../ui/IconAnimations";

export default function Partners() {

  return (
    <>

    <Flex
      id="partners"
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      bg="URL('./images/partners-bg.png')"
      bgRepeat="no-repeat"
      w="100%"
      bgSize="cover"
      p={{ xxl: "25px 0px", xl: "25px 50px", xs: "25px 50px", lg: "0px 50px 30px", md: "0px 50px 30px", sm: "0px 50px 30px" }}
    >

      <VStack
        maxW={{ xl: "1200px" }}
        w="100%"
        m="0px auto"
        pos="relative"
        textAlign="center"
      >

      <Box
        w="100%"
        pos="relative"
        m="50px 0px"
      >

          <Heading
            as="h2"
            textTransform="uppercase"
            fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
          >
            <Icon as={BsAwardFill}
                  bg="#e04e12"
                  color="#ffffff"
                  borderRadius="50%"
                  border="1px solid #ffffff"
                  p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                  m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
            />
            Наши партнеры
          </Heading>

      </Box>

      <Box
        w="100%"
        pos="relative"
      >

          <Wrap align="center" spacing="10px" justify="center">

          { partners.map( ( partner: any, key: any ) => (

              <WrapItem
                  align="center"
                  textAlign="center"
                  key={key}
                  w="19%"
              >

                <Image src={partner.logo} />

                <Badge
                    as="h6"
                    fontSize="14px"
                    color="#8a8a8a"
                    lineHeight="1.5"
                    m="0px auto"
                    display="none"
                >
                  {partner.name}
                </Badge>

              </WrapItem>

          ) ) }

          </Wrap>


      </Box>

      </VStack>

    </Flex>

    </>
  );
}
