import React from "react";

import { Step, Steps, useSteps } from 'chakra-ui-steps';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useDisclosure
} from "@chakra-ui/react";

const content = (
    <Flex py={4} textAlign="center">
      нет данных
    </Flex>
);

const steps = [
  { label: "Шаг 1", content },
  { label: "Шаг 2", content }
];

export default function SpecificationFeedback() {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  return (
      <>
        <Button
            color="#ffffff"
            border="1px solid #0094d8"
            p={{ xxl: "30px 50px", xl: "30px 50px", xs: "30px 50px", lg: "30px 50px", md: "0px 3px", sm: "0px 3px" }}
            bg="none"
            textTransform="uppercase"
            mt={4}
            float="left"
            w={{ xxl: "60%", xl: "60%", xs: "60%", lg: "60%", md: "100%", sm: "100%" }}
            fontSize={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "14px", sm: "14px" }}
            onClick={onOpen}
            className="service-button"
            _hover= {{
              bg: 'none'
            }}
        >
          заполнить техническое задание
        </Button>
    <Modal isOpen={isOpen} onClose={onClose}>

      <ModalOverlay />

      <ModalContent
          bg="#005c89db"
      >

        <ModalHeader
            textTransform="uppercase"
            bg="#000000"
            fontSize="14px"
        >
          техническое задание
        </ModalHeader>

        <ModalCloseButton
            bg="#101010"
            borderRadius="0px"
            _focus={{
              boxShodow: 'none',
              border: 'none'
            }}
        />

        <ModalBody>

          <Steps activeStep={activeStep}>

            {steps.map(({ label, content }) => (
                <Step
                    label={label}
                    key={label}
                    className="modal-step"
                >
                  {content}
                </Step>
            ))}

          </Steps>

        </ModalBody>

        <ModalFooter>
          {activeStep === 2 ? ( <>
              <Button
                  onClick={prevStep}
                  color="#ffffff"
                  bg="none"
                  border="1px solid #0094d8"
                  textTransform="uppercase"
                  fontSize="12px"
                  w="50%"
                  borderRadius="0px"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  className="service-button"
                  _hover= {{
                    bg: 'none'
                  }}
              >
                Назад
              </Button>

              <Button
                  onClick={onClose}
                  color="#ffffff"
                  bg="none"
                  border="1px solid #0094d8"
                  textTransform="uppercase"
                  fontSize="12px"
                  w="50%"
                  borderRadius="0px"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  className="service-button"
                  _hover= {{
                    bg: 'none'
                  }}
              >
                Завершить
              </Button>

          </>) : ( activeStep === 0
              ? <Button
                      onClick={nextStep}
                      color="#ffffff"
                      bg="none"
                      border="1px solid #0094d8"
                      textTransform="uppercase"
                      fontSize="12px"
                      w="100%"
                      borderRadius="0px"
                      _focus={{
                        boxShadow: 'none',
                      }}
                      className="service-button"
                      _hover= {{
                        bg: 'none'
                      }}
                  >
                    Далее
                  </Button>
              : ( <>

              <Button
                  onClick={prevStep}
                  color="#ffffff"
                  bg="none"
                  border="1px solid #0094d8"
                  textTransform="uppercase"
                  fontSize="12px"
                  w="50%"
                  borderRadius="0px"
                  _focus={{
                    boxShadow: 'none'
                  }}
                  className="service-button"
                  _hover= {{
                    bg: 'none'
                  }}
              >
                Назад
              </Button>

              <Button
                  onClick={nextStep}
                  color="#ffffff"
                  bg="none"
                  border="1px solid #0094d8"
                  textTransform="uppercase"
                  fontSize="12px"
                  w="50%"
                  borderRadius="0px"
                  _focus={{
                    boxShadow: 'none',
                  }}
                  className="service-button"
                  _hover= {{
                    bg: 'none'
                  }}
              >
                  Далее
              </Button>
            </>
            )

          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
}
