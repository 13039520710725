import React, { useState } from "react";

import { useSpring, animated, config } from 'react-spring'

import {
  Flex,
  Image,
  Heading,
  Box,
  Text,
  Link,
  Button,
  SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"


import ModalVideo from 'react-modal-video';

import { services } from "../../store/services";

import { Icon } from "@chakra-ui/react"

import map from "lodash/map";

import { AiOutlineSafetyCertificate } from "react-icons/ai";

const Service = ({ thumbnail, title, desc, bg, src, position, ...rest } : any ) => {

  const onClick = (e: any) => {

    e.preventDefault();

    const el = document.getElementById( e.target.parentElement.attributes.href.value.slice(1) );

    if ( el ) {

      el.scrollIntoView({
        behavior:"smooth"
      } );

    }

  };

  const [isOpen, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const hoverState = useSpring({
    transform: hover
      ? 'translate3d(0px, -12px, 0px) scale(1.03)'
      : 'translate3d(0px, 0px, 0px) scale(1)',
    config: config.wobbly
  });

  const onClose = () => {
      setOpen(false)
  };

  return ( <>

      <Flex
        align="center"
        margin="0px auto"
        w="100%"
        display="block"
        {...rest}
      >

        <Box
            w={{ xxl: "50%", xl: "50%", xs: "50%", lg: "100%", md: "100%", sm: "100%" }}
            float={ [ 1 ,3 ].indexOf(position) >= 0
              ? 'right'
              : 'left'
            }
        >

          <animated.div
            style={{
              width: '100%',
              ...hoverState
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >

            <Image
              src={thumbnail}
              minH={{ xxl : "252px", xl: "252px", xs: ( position === 0 ? "252px" : "276px" ), lg: ( position === 0 ? "276px" : "276px" ), md: ( position === 0 ? "276px" : "276px" ) }}
            />

          </animated.div>

        </Box>

          <Box
              w={{ xxl: "50%", xl: "50%", xs: "50%", lg: "100%", md: "100%", sm: "100%" }}
              bg={[`URL(${bg}) no-repeat`]}
              bgSize="cover"
              textAlign={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "center", md: "center", sm: "center" }}
              float={ [ 1 ,3 ].indexOf(position) >= 0
                  ? 'left'
                  : 'right'
              }
              p="20px"
          >

            <Heading
              as="h4"
              fontSize="17px"
              textTransform="uppercase"
              m="0px"
              lineHeight="1.5"
            >
              {title}
            </Heading>

            <Text
              m="15px 0px"
              fontSize={ { xxl: "12px", xl: "12px", xs: "12px", lg: "12px", md: "12px" } }
              lineHeight="2.0"
              minHeight={{ xxl: position == 3 ? "120px" : 'auto', xl: position == 3 ? "120px" : 'auto', xs: position == 3 ? "143px" : 'auto', lg: position == 3 ? "143px" : 'auto', md: "120px" }}
              p={ position == 1
                  ? '12px 0px'
                  : '0px'
              }
            >
              {desc}
            </Text>

              { position == 3 &&

                <Link
                    srl_video_caption="Audio Track"
                  href="#video"
                  p="15px"
                  borderRadius="5px"
                  color="#ffffff !important"
                  textDecoration="none !important"
                  border="1px solid #ffffff"
                    onClick={() => setOpen(true)}
                >

                <Button
                  bg="none"
                  fontSize="10px"
                  p="0px 30px"
                  className="service-button"
                  mt="-3px"
                  _hover={{
                    bg: "none"
                  }}
                >
                    ПОСМОТРЕТЬ ВИДЕО
                </Button> </Link>}

                    {position != 3 &&  <Link
                        srl_video_thumbnail="https://url/to/my_audio_thumbnail.jpg"
                        srl_video_caption="Audio Track"
                        href="#feedback"
                        p="15px"
                        borderRadius="5px"
                        color="#ffffff !important"
                        textDecoration="none !important"
                        border="1px solid #ffffff"
                        onClick={ onClick }
                    >

                        <Button
                            bg="none"
                            fontSize="10px"
                            p="0px 30px"
                            className="service-button"
                            mt="-3px"
                            _hover={{
                                bg: "none"
                            }}
                        >
                            ПОДАТЬ ЗАЯВКУ
                        </Button> </Link>}



          </Box>

      </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>

              <ModalOverlay />

              <ModalContent
                  p="0px"
                  minWidth={{ xxl: "600px", xl: "600px", xs: "600px", lg: "600px", md: "600px", sm: "350px" }}
                  w={{ xxl: "600px", xl: "600px", xs: "600px", lg: "600px", md: "600px", sm: "350px" }}
              >

                  <ModalCloseButton
                      position="absolute"
                      top="2px"
                      right="2px"
                      color="#ffffff"
                  />

                  <ModalBody p={0}>

                      <ModalVideo
                          channel="custom"
                          url={src}
                          isOpen={isOpen}
                          onClose={() => setOpen(false)}
                      />

                  </ModalBody>

              </ModalContent>

          </Modal>
    </>
  )
};

export default function Services() {
  return (
    <>

      <Flex
        maxW={{ xl: "1200px" }}
        w="100%"
        pos="relative"
        m="50px 0px"
        id="services"
      >

        <Heading
          as="h2"
          w="100%"
          textAlign="center"
          fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
          textTransform={ { xxl: "uppercase", xl: "uppercase", xs: "uppercase", lg: "uppercase", md: "uppercase", sm: "uppercase" } }
        >

          <Icon as={AiOutlineSafetyCertificate}
                bg="#e04e12"
                color="#ffffff"
                borderRadius="50%"
                border="1px solid #ffffff"
                p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
          />

          услуги компании

        </Heading>

      </Flex>

      <Flex
        align="center"
        maxW={{ xl: "1200px" }}
        w="100%"
        mb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "25px", sm: "25px" }}
      >

        <SimpleGrid
          columns={1}
          spacingX="40px"
          spacingY="20px"
          alignItems="center"
          w="100%"
        >

          { map( services, ( service: any, key: any ) => (

            <Service
                thumbnail={service.thumbnail}
                title={service.title}
                desc={service.desc}
                bg={service.background}
                src={service.src}
                position={key}
                key={key}
            />

          ) ) }

        </SimpleGrid>

      </Flex>

    </>
  );
}
