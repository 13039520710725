import React from "react";

import LandingLayout from "../components/layouts/LandingLayout";

import AboutUs from "../components/sections/AboutUs";
import Anniversary from "../components/sections/Anniversary";
import Contacts from "../components/sections/Contacts";
import Feedback from "../components/sections/Feedback";
import Services from "../components/sections/Services";
import Slider from "../components/sections/Slider";
import Team from "../components/sections/Team";
import Projects from "../components/sections/Projects";
import Partners from "../components/sections/Partners";

export default function Landing() {
  return ( <LandingLayout>
    <>
      <Slider />
      <Services />
      <AboutUs/>
      <Team/>
      <Projects/>
      <Anniversary />
      <Partners/>
      <Feedback/>
      <Contacts />
    </>
    </LandingLayout>
  );
}
