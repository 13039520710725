import React, { MutableRefObject, useEffect, useRef } from "react";

import { Loader } from "@googlemaps/js-api-loader";

import throttle from "lodash/throttle";

const loader = new Loader({
  apiKey: 'AIzaSyCan1IL1ZSzjqRJ-qmfKPfcVS-WsROkMM8',
  version: 'quarterly'
});

const loadPromise = loader.load();

const style = {
  width: "100%",
  height: "650px",
};

type Coordinate = number;

interface GMapLocationSelectorProps {
  lat: Coordinate;
  lng: Coordinate;
}

export default function GMapLocationSelector({
                                               lat,
                                               lng,
                                             }: GMapLocationSelectorProps) {
  const map = useRef<google.maps.Map | null>(null);
  const marker = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (map.current && marker.current) {
      updatePosition(map, marker, lat, lng);
    }
  }, [lat, lng]);

  const refHandler = (div: HTMLDivElement) => {
    setupMap(map, marker, div, lat, lng );
  };

  return <div style={style} ref={refHandler} />;
}

function setupMap(
  map: MutableRefObject<google.maps.Map | null>,
  marker: MutableRefObject<google.maps.Marker | null>,
  ref: HTMLDivElement | null,
  lat: Coordinate,
  lng: Coordinate
) {
  if (ref === null) {
    google.maps.event.trigger(map.current, "resize");
    return;
  }

  if (map.current) {
    return;
  }

  loadPromise.then(() => {
    map.current = new google.maps.Map(ref, {
      center: {
        lat: lat,
        lng: lng
      },
      zoom: 14,
    });

    marker.current = new google.maps.Marker({
      position: {
        lat: lat,
        lng: lng
      },
      map: map.current as google.maps.Map,
    });

  });
}

const updatePosition = throttle(
  (
    map: MutableRefObject<google.maps.Map | null>,
    marker: MutableRefObject<google.maps.Marker | null>,
    lat: Coordinate,
    lng: Coordinate
  ) => {
    if (!lat || !lng) {
      return;
    }

    const coords = new google.maps.LatLng(lat, lng);
    map.current?.panTo(coords);
    marker.current?.setPosition(coords);

    if (!marker.current) {
      marker.current = new google.maps.Marker({
        position: coords,
        map: map.current as google.maps.Map,
      });
    }
  },
  650
);