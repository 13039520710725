import React from "react";
import { Icon } from "@chakra-ui/react"

import {
  AiOutlineExclamation, AiFillNotification
} from "react-icons/ai";

import { ReactComponent as Feedback } from "assets/icons/feedback.svg";
import { ReactComponent as Commercial } from "assets/icons/commercial.svg";
import { ReactComponent as Appointment } from "assets/icons/appointment.svg";
import { ReactComponent as ETA } from "assets/icons/estimate.svg";
import { ReactComponent as Agreement } from "assets/icons/agreement.svg";

import {
  Flex,
  VStack,
  Heading,
  Box,
  SimpleGrid,
  Text
} from "@chakra-ui/react"

import Move from "../ui/IconAnimations";

const Step = ( { icon, title, step, position, ...rest } : any  ) => {

  return (

    <Box
      p="20px"
      align="center"
      textAlign="center"
      pos="relative"
      mt={ [ 2, 4 ].indexOf( position ) >= 0
          ? { xxl: '225px', xl: '225px', xs: '225px', lg: '225px', md: '0px', sm: '0px' }
          : '0px'
      }
      _after={{
        content: '""',
        bg: position !== 5
            ? [`URL(./images/step-${position}.png) no-repeat`]
            : '',
        pos    : 'absolute',
        display: { xxl: 'block', xl: 'block', xs: 'block', lg: 'block', md: 'none', sm: 'none' },
        right  : position === 4
            ? '-115%'
            : position === 3
                ? '-90%'
                : '-47%',
        w: '100%',
        h: '500px',
        bottom: [ 1, 4 ].indexOf( position ) >= 0
            ? '-300px'
            : '-185px'

      }}
    >

      <Move
        y={20}
      >

        <Icon
          as={icon}
          fontSize="45px"
          mb="15px"
          fill="#ff0000"
        />

      </Move>

      <Heading
        as="h6"
        fontSize="18px"
        lineHeight="1.5"
        m="0px auto"
        textTransform="uppercase"
      >
        {step}
      </Heading>

      <div
          style={{
            marginTop: '15px',
            fontSize: '12px'
          }}
          dangerouslySetInnerHTML={{ __html: title}}
      />

    </Box>
  )

};

export default function AboutUs() {

  return (
    <>

    <Flex
      id="aboutUs"
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction="column"
      bg="URL('./images/aboutUs.jpg')"
      bgRepeat="no-repeat"
      w="100%"
      bgSize="cover"
      maxW={{ xl: "1200px" }}
      pos="relative"
      textAlign="center"
      m="0px auto"
      p={{ xxl: "25px 0px 60px", xl: "25px 50px 60px", xs: "25px 50px 60px", lg: "50px", md: "50px", sm: "50px" }}

    >

      <Box
        w="100%"
        pos="relative"
        m="50px 0px"
      >

          <Heading
            as="h2"
            textTransform="uppercase"
            fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
          >
            <Icon as={AiFillNotification}
                  bg="#e04e12"
                  color="#ffffff"
                  borderRadius="50%"
                  border="1px solid #ffffff"
                  p={ { xxl: "5px 10px", xl: "5px 10px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                  m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
            />
            Как мы работаем
          </Heading>

      </Box>

      <Box
        w="100%"
        pos="relative"
      >

        <SimpleGrid columns={{ xxl: 5, xl: 5, xs: 5, lg: 5, md: 1, sm: 1 }} spacing={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "0px", sm: "25px" }}>

            <Step
              icon={Feedback}
              step="ШАГ ПЕРВЫЙ"
              title='Вы <span style="color:#e04e12">заполняете форму</span> обратной связи или <span style="color:#e04e12">техническое задание</span>'
              position={1}
            />

          <Step
            icon={Commercial}
            step="ШАГ второй"
            title="Мы готовим коммерческое предложение в 3х вариантах"
            position={2}
          />

          <Step
            icon={Appointment}
            step="ШАГ третий"
            title="Встречаемся на объекте, и Вы определяетесь с бюджетом"
            position={3}
          />

          <Step
            icon={ETA}
            step="ШАГ четвертый"
            title="Составляем смету, назначаем сроки и подписываем договор"
            position={4}
          />

          <Step
            icon={Agreement}
            step="ШАГ Пятый"
            title="Выполняем взаимные договоренности и пьем шампанское"
            position={5}
          />

       </SimpleGrid>

      </Box>

    </Flex>

    </>
  );
}
