import ceo from '../assets/images/team/Alisher-Efremov.png';

import cto from '../assets/images/team/Evgeniy-Shmarin.png';
import director from '../assets/images/team/Oleg-Lotov.png';
import engineer from '../assets/images/team/Peter-Glechikov.png';

export const team = {
  main: [ {
    avatar  : ceo,
    name    : 'Алишер Ефремов',
    position: 'Генеральный директор'
  }, {
    avatar  : cto,
    name    : 'Евгений Шмарин',
    position: 'Технический директор'
  }, {
    avatar  : engineer,
    name    : 'Петр Глечиков',
    position: 'Главный инженер'
  } ]
};