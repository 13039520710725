import { useForm } from "react-hook-form";

import React, { useState, useReducer } from "react";

import emailjs from 'emailjs-com';

import set from "lodash/set";

import {
  BsChatSquareQuoteFill
} from "react-icons/bs";

import {
  Icon,
  Flex,
  Heading,
  Box,
  Text,
  Button,
  Link,
  FormControl,
  Input,
  Textarea
} from "@chakra-ui/react";

import SpecificationFeedback from "../modals/SpecificationFeedback";

const initialState = initialValues();

function reducer(state: any, action: any) {
  return set({ ...state }, action.key, action.value);
}

export default function Feedback() {

  const { handleSubmit, formState } = useForm();
  const [ state, dispatch ]         = useReducer(reducer, initialState);
  const imageUploader               = React.useRef<any>({});

  const handleImageUpload = () => (e: any) => {

    const [ file ] = e.target.files;

    if ( file ) {

      const reader  = new FileReader();
      reader.onload = ( e : any ) => {

        dispatch({
          key  : 'attachment',
          value: [
            e.target.result
          ],
        } );

      };

      reader.readAsDataURL(file);

      dispatch({
        key: 'attach',
        value: 'Файл добавлен'
      } );

    }

  };

  function onSubmit( values : any ) {

    return new Promise(resolve => {

      emailjs.send('everest_building_group', 'template_vtif6q3', state, 'user_Pqp3Ka0380hmBQGxbETOw')
        .then((result) => {
          resolve( formState.isSubmitting );
        }, (error) => {
          resolve( formState.isSubmitting );
        } );
    });

  }

  const handleChange = ( key: string ) => (e: any) => {

    dispatch({
      key,
      value: e.target.value,
    });
  };

  return (

    <Flex
      id="feedback"
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction="column"
      bg="URL('./images/contact-us-bg.png')"
      bgRepeat="no-repeat"
      bgSize="cover"
      w="100%"
      p={{ xxl: "45px 0px", xl: "45px 50px", xs: "45px 50px", lg: "45px 50px", md: "0px 40px 25px", sm: "0px 40px 25px" }}
    >

      <Box
          maxW={{ xl: "1200px" }}
          w="100%"
      >

        <Heading
            as="h2"
            w="100%"
            textAlign="center"
            textTransform="uppercase"
            m="40px 0px 10px"
            fontSize={{ xxl: "32px", xl: "32px", xs: "32px", lg: "32px", md: "24px", sm: "24px" }}
        >
          <Icon as={BsChatSquareQuoteFill}
                bg="#e04e12"
                color="#ffffff"
                borderRadius="50%"
                border="1px solid #ffffff"
                p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
          />
          ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ
        </Heading>

        <Text
            textAlign="center"
            fontWeight="100"
        >
          ПО ОБОРУДОВАНИЮ, ПРОЕКТИРОВАНИЮ И МОНТАЖУ <br />
          СИСТЕМЫ ВЕНТИЛЯЦИИ, СИСТЕМ ОТОПЛЕНИЯ.
        </Text>

      </Box>

      <Box
          textAlign="center"
          m="50px 0px"
          maxW={{ xl: "600px" }}
          w="100%"
      >

        {formState.isSubmitted
            ? <Box
                bg="#ff0000"
                color="#ffffff"
                textTransform="uppercase"
                p={5}
                fontSize={12}
                fontWeight="bold"
            >
              Ваша заявка отправленна
            </Box>
            : ''
        }

        <form onSubmit={handleSubmit( onSubmit )} id="feed-back-form">

          <FormControl
              mb="25px"
              id="name"
              isRequired
              w="49%"
              mr="2%"
              float="left"
          >

            <Input
                h="55px"
                bg="none"
                borderRadius="0px"
                textAlign="center"
                border="none"
                borderBottom="1px solid #e04e12"
                color="#ffffff"
                placeholder="Введите ваше имя"
                name="name"
                value={state.name}
                onChange={handleChange("name")}
                _focus={{
                  boxShadow: 'none'
                }}
                required
            />

          </FormControl>

          <FormControl
              mb="25px"
              id="phone"
              isRequired
              w="49%"
              float="left"
          >

            <Input
                h="55px"
                bg="none"
                borderRadius="0px"
                textAlign="center"
                border="none"
                borderBottom="1px solid #e04e12"
                color="#ffffff"
                placeholder="Введите ваш номер телефона"
                name="phone"
                value={state.phone}
                onChange={handleChange("phone")}
                _focus={{
                  boxShadow: 'none'
                }}
                required
            />

          </FormControl>

          <FormControl
              mb="25px"
              id="message"
              float="left"
              isRequired
          >

              <Textarea
                  h="40px"
                  bg="none"
                  borderRadius="0px"
                  textAlign="center"
                  border="none"
                  borderBottom="1px solid #e04e12"
                  color="#ffffff"
                  placeholder="Текст сообщения"
                  name="message"
                  value={state.message}
                  onChange={handleChange("message")}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  required
              />

          </FormControl>

          <Box
              w="100%"
              m="50px 0px"
          >

            <input
                type="file"
                onChange={ handleImageUpload() }
                ref={imageUploader}
                style={{
                  display: "none"
                }}
            />

            <Text
                fontSize="12px"
                color="#a49494"
            >
              По возможности, приложите к заявке файл с планами <br />
              объекта или спецификацией оборудования
            </Text>

            <Link
                color="#e04e12 !important"
                float="left"
                w="100%"
                textAlign="center"
                pt="15px"
                textDecoration="none !important"
                fontSize="12px"
                fontWeight="bold"
                textTransform="uppercase"
                onClick={() => imageUploader.current.click()}
            >
              {state.attach}
            </Link>

          </Box>

          <FormControl
              float="left"
              w="100%"
          >

            <Button
                color="#ffffff"
                border="none"
                p={{ xxl: "30px 50px", xl: "30px 50px", xs: "30px 50px", lg: "30px 50px", md: "0px 3px", sm: "0px 3px" }}
                bg="#e04e12"
                textTransform="uppercase"
                mt={4}
                isLoading={formState.isSubmitting}
                fontSize={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "14px", sm: "14px" }}
                float="left"
                w={{ xxl: "38%", xl: "38%", xs: "38%", lg: "38%", md: "100%", sm: "100%" }}
                mr={{ xxl: "2%", xl: "2%", xs: "2%", lg: "2%", md: "0px", sm: "0px" }}
                className="service-button"
                _hover= {{
                 bg: 'none'
                }}
                type="submit"
            >
              оформить заявку
            </Button>

            <SpecificationFeedback />

          </FormControl>

        </form>
      </Box>

    </Flex>
  );
}

function initialValues(): any {

  return {
    name      : '',
    phone     : '',
    message   : '',
    attachment: [],
    attach    : '+ ДОБАВИТЬ ФАЙЛ'
  };

}
