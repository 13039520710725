import React, { useState } from "react";

import { useSpring, animated, config } from 'react-spring'

import {
  Flex,
  VStack,
  Stack,
  Icon,
  Box,
  Heading,
  Text,
  Badge,
  Link,
  Button,
  SimpleGrid,
  Center
} from "@chakra-ui/react";

import Header from "../sections/Header";

import { AiOutlineCheck } from "react-icons/ai";

import Move from "../ui/IconAnimations";

import { sliders } from "../../store/sliders";

const Slide = ( { title, ...rest } : any ) => {

  return (
      <Box
          align="center"
          margin="0px auto"
          w="100%"
          textAlign="center"
      >

          <Icon
              as={AiOutlineCheck}
              fontSize="30px"
              color="#e04e12"
              p="5px"
              float={{ xxl: "left", xl: 'left', xs: 'left', lg: 'none', md: 'none', sm: 'none' }}
          />

        <Heading
            as="h6"
            fontSize="16px"
            fontFamily="GothamPro"
            fontWeight="500"
            p="5px"
            display={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inline-block", md: "inline-block", sm: "inline-block" }}
        >
          {title}
        </Heading>

      </Box>

  );


};

export default function Slider() {

  const [hover, setHover] = useState(false);
  const hoverState = useSpring({
    transform: hover
      ? 'translate3d(0px, -12px, 0px) scale(1.03)'
      : 'translate3d(0px, 0px, 0px) scale(1)',
    config: config.wobbly
  });

  return (
    <Flex
      align="center"
      bg="URL('./images/slider.jpg') no-repeat"
      bgPosition={{ xxl: "bottom", xl: "bottom", xs: "bottom", lg: "bottom", md: "top", sm: "top" }}
      w="100%"
      p={{ xxl: "0px", xl: "0px 50px", xs: "0px 50px" }}
      pos="relative"
    >

      <VStack
        maxW={{ xl: "1200px" }}
        w="100%"
        m="0px auto"
      >

        <Header />

        <Box
          m={{ xxl: "150px 0px 80px !important", xl: "150px 0px 80px !important", xs: "150px 0px 80px !important", lg: "150px 0px 80px !important", md: "30px 0px 60px!important", sm: "30px 0px 60px!important" }}
          textAlign="center"
        >

          <Box>

            <Heading
              as="h2"
              textTransform="uppercase"
              fontSize={{ xxl: "32px", xl: "32px", xs: "32px", lg: "32px", md: "24px", sm: "24px" }}
              mb="70px"
            >
              СОВРЕМЕННЫЕ СИСТЕМЫ <br />
              ВЕНТИЛЯЦИИ И КОНДИЦИОНИРОВАНИЯ
            </Heading>

            <Text
              color={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ffffff", sm: "#ffffff" }}
              fontWeight={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "bold", sm: "bold" }}
            >
              Занимайтесь своим любимым делом, а о чистом воздухе позаботимся мы.
            </Text>

          </Box>

        </Box>

        <Box
            w="100%"
            align="center"
        >

          <SimpleGrid
              columns={{ xxl: 4, xl: 4, xs: 4, lg: 4, md: 1, sm: 1 }}
              spacing={{ xxl: "15px", xl: "15px", xs: "15px", lg: "15px", md: "15px", sm: "15px" }}
              w="100%"
              align="center"
          >

            <Slide
                title='Грамотное проектирование'
            />

            <Slide
                title='Монтаж с гарантией'
            />

            <Slide
                title='Пусконаладочные работы'
            />

            <Slide
                title='Сервисное обслуживание'
            />

          </SimpleGrid>

        </Box>

        <Box
            w="100%"
            m={{ xxl: "200px 0px 80px !important", xl: "200px 0px 80px !important", xs: "200px 0px 80px !important", lg: "200px 0px 80px !important", md: "50px 0px 25px !important", sm: "50px 0px 25px !important" }}
            _before={{
              content: '""',
              bg: 'URL("./images/left-hand.png") no-repeat',
              pos: 'absolute',
              left: { xxl: '0px', xl: '-16%', xs: '-20%' },
              display: { xxl: 'block', xl: 'block', xs: 'none', lg: 'none', md: 'none', sm: 'none' },
              w: '100%',
              h: '500px',
              bottom: '-45px'
            }}
            _after={{
              content: '""',
              bg: 'URL("./images/right-hand.png") no-repeat',
              pos: 'absolute',
              right: { xxl: '-74%', xl: '-82%', xs: '-85%' },
              display: { xxl: 'block', xl: 'block', xs: 'none', lg: 'none', md: 'none', sm: 'none' },
              w: '100%',
              h: '500px',
              bottom: '-45px'
            }}
        >

          <SimpleGrid
              columns={{ xxl: 4, xl: 4, xs: 4, lg: 4, md: 1, sm: 1 }}
              spacing={{ xxl: "10px", xl: "10px", xs: "10px", lg: "35px", md: "35px", sm: "35px" }}
              m="0px 150px"
          >

            { sliders.map( ( slider: any ) => (

                <Box
                    w="100%"
                    textAlign="center"
                    key={slider.title}
                >

                  <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >

                    <Icon
                        as={slider.icon}
                        p="5px"
                        w="75px"
                        h="100px"
                    />

                  </Move>

                  <Heading
                      as="h4"
                      fontSize="18px"
                      mt="15px"
                  >
                    <div dangerouslySetInnerHTML={{ __html: slider.title}} />
                  </Heading>

                </Box>

            ) ) }

          </SimpleGrid>

        </Box>


      </VStack>

    </Flex>
  );
}
