import { ReactComponent as Buildings } from "assets/icons/buildings.svg";
import { ReactComponent as Factories } from "assets/icons/factories.svg";
import { ReactComponent as Hospitals } from "assets/icons/hospitals.svg";
import { ReactComponent as Arts } from "assets/icons/arts.svg";
import { ReactComponent as Restaurants } from "assets/icons/restaurants.svg";

import ofb from '../assets/images/projects/OFB.jpg';
import befit from '../assets/images/projects/BEFIT.jpg';
import crownPlaza from '../assets/images/projects/Crown Plaza.jpg';
import commercialZone from '../assets/images/projects/Commercial Zone.jpg';

import compas from '../assets/images/projects/COMPAS.jpg';
import riviera from '../assets/images/projects/RIVIERA.jpg';
import eskiShahar from '../assets/images/projects/Eski Shahar.jpg';
import anhor from '../assets/images/projects/Anhor Park.jpg';

import makroM from '../assets/images/projects/Makro - Mahtumkuli.jpg';
import makroT from '../assets/images/projects/Makro - Turkistan.jpg';
import mid from '../assets/images/projects/MID.jpg';
import avtotest from '../assets/images/projects/Avtotest.jpg';

import south from '../assets/images/projects/Railway Station - South.jpg';
import xiva from '../assets/images/projects/Railway Station - Xiva.jpg';
import namangan from '../assets/images/projects/Railway Station - Namangan.jpg';
import school from '../assets/images/projects/President School.jpg';

import abk from '../assets/images/projects/ABK.jpg';
import befitEco from '../assets/images/projects/BEFIT ECO.jpg';
import ofbChakb from '../assets/images/projects/CHAKB OFB.jpg';
import holidayInn from '../assets/images/projects/Holiday INN.jpg';

import makroC1 from '../assets/images/projects/Makro - C1.jpg';
import orient from '../assets/images/projects/ORIENT Business Center.jpg';
import prosecutorOffice from '../assets/images/projects/Prosecutor Office.jpg';
import unicef from '../assets/images/projects/Unicef.jpg';

import foodMall from '../assets/images/projects/Food Mall.jpg';
import amirsoy from '../assets/images/projects/Амирсай 229.jpg';
import crowne from '../assets/images/projects/Crowne Plaza.jpg';

import regalStayResidences from '../assets/images/projects/Regal Stay Residences.jpg';


export const projects = {
  catalogs: [ {
      icon: Buildings,
      name: 'ТРЦ, бизнес-центры <br/> и административные <br/> здания'
  }, {
      icon: Factories,
      name: 'Промышленные <br /> предприятия, <br /> фабрики, заводы'
  }, {
      icon: Hospitals,
      name: 'Клиники, медицинские <br /> лаборатории и <br /> фармацевтические предприятия'
  }, {
      icon: Arts,
      name: 'Строительные компании <br /> студии дизайна и <br /> архитектурное бюро'
  }, {
      icon: Restaurants,
      name: 'Рестораны, кальян-бары <br /> и развлекательные <br /> заведения'
  } ],
  items: [ {
      src  : ofb,
      title: 'Головной офис «Orient Finance Bank»',
      desc : 'Общая мощность по холоду 1000 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 2 шт, ' +
          'приточные установки  - 12 шт, ' +
          'канальные и наполь фанкойлы - 142 шт, ' +
          'вытяжные вентиляторы - 30 шт, ' +
          'мощность котельного оборудования 1300 кВт, ' +
          'прецизионные кондиционеры 2 шт'
  }, {
      src  : befit,
      title: 'Фитнес-центр и футзал «BeFit»',
      desc : 'Общая мощность по холоду - 600 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 1 шт, ' +
          'крышные кондиционеры (руфтопы) - 14 шт, ' +
          'приточные установки  - 6 шт, ' +
          'канальные и кассетные фанкойлы - 98 шт, ' +
          'вытяжные вентиляторы - 12 шт, ' +
          'мощность котельного оборудования - 1900 кВт, ' +
          'центральный осушитель воздуха - 2 шт'
  }, {
      src  : crownPlaza,
      title: 'Отель «Crown Plaza»',
      desc : 'Отель «Crown Plaza»'
  }, {
      src  : commercialZone,
      title: 'Коммерческая зона на Ц-1',
      desc : 'Коммерческая зона на Ц-1'
  }, {
      src  : compas,
      title: 'Торгово-развлекательный центр «Compass»',
      desc : 'Общая площадь квадратных метров - 35000 Общая мощность по холоду - 3800 кВт Установлено: холодильные машины (чиллеры) - 2 шт, крышные кондиционеры (руфтопы) - 14 шт, приточные установки  - 6 шт, канальные и кассетные фанкойлы - 236 шт, вытяжные вентиляторы - 92 шт мощность котельного оборудования - 8400 кВт'
  }, {
      src  : riviera,
      title: 'Торгово-развлекательный центр «Riviera»',
      desc : 'Общая площадь квадратных метров - 40 370 ' +
          'Общая мощность по холоду - 4300 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 3шт, ' +
          'крышные кондиционеры (руфтопы) - 14 шт, ' +
          'приточные установки - 18 шт, ' +
          'канальные и кассетные фанкойлы - 203 шт, ' +
          'вытяжные вентиляторы - 96 шт, ' +
          'мощность котельного оборудования - 6200 кВт'
  }, {
      src  : eskiShahar,
      title: 'Торговый центр «Eski Shahar»',
      desc : 'Общая площадь квадратных метров - 25 765 ' +
          'Общая мощность по холоду - 2000 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 2 шт, ' +
          'приточные установки - 22 шт, ' +
          'канальные и кассетные фанкойлы - 151 шт, ' +
          'мощность котельного оборудования - 2160 кВт'
  }, {
      src  : anhor,
      title: 'Торговый центр «Anhor Park»',
      desc : 'Общая мощность по холоду 650 кВт ' +
          'Установлено: ' +
          'канальные, кассетные и однопоточные фанкойлы - 37 шт, ' +
          'вытяжные вентиляторы - 20 шт, ' +
          'мощность котельного оборудования 1750 кВт'
  }, {
      src  : makroM,
      title: 'ТЦ «Makro» на ул. Махтумкули',
      desc : 'Общая мощность по холоду - 750 кВт ' +
          'Установлено: ' +
          'канальные, кассетные и однопоточные фанкойлы - 26 шт, ' +
          'вытяжные вентиляторы - 15 шт, ' +
          'мощность котельного оборудования 700 кВт'
  }, {
      src  : makroT,
      title: 'ТЦ «Makro» на ул. Туркистан',
      desc : 'ТЦ «Makro» на ул. Туркистан'
  }, {
      src  : mid,
      title: 'Министерство инновационного развития',
      desc : 'Общая мощность по холоду - 3200 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 4 шт, ' +
          'канальные, кассетные и наполтные фанкоилы - 482 шт, ' +
          'приточные установки  - 10 шт, ' +
          'крышные кондиционеры (руфтопы) - 2 шт, ' +
          'прецизионные кондиционеры - 6 шт, ' +
          'мощность котельного оборудования - 4200 кВт, ' +
          'вытяжные вентиляторы - 32, ' +
          'система диспетчеризации, ' +
          'система ГВС на солнечных коллеторах OVENTROP'
  }, {
      src  : avtotest,
      title: 'Главный офис «Avtotest»',
      desc : 'Главный офис «Avtotest»'
  }, {
      src  : south,
      title: 'Железнодорожный вокзал «Южный»',
      desc : 'Железнодорожный вокзал «Южный»'
  }, {
      src  : xiva,
      title: 'Железнодорожный вокзал «Хива»',
      desc : 'Железнодорожный вокзал «Хива»'
  }, {
      src  : namangan,
      title: 'Железнодорожный вокзал «Наманган»',
      desc : 'Железнодорожный вокзал «Наманган»'
  }, {
      src  : school,
      title: 'Президентская школа в городе Хива',
      desc : 'Президентская школа в городе Хива'
  }, {
      src  : abk,
      title: 'АБК',
      desc : 'Общая мощность по холоду - 1000 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 2 шт, ' +
          'приточные установки - 15 шт, ' +
          'напольные и кассетные фанкойлы - 181 шт, ' +
          'вытяжные вентиляторы - 12 шт, ' +
          'мощность котельного оборудования - 1500 кВт'
  }, {
      src  : befitEco,
      title: 'Фитнес-центр BEFIT ECO',
      desc : 'Общая мощность по холоду - 240 кВт ' +
          'Установлено: ' +
          'приточные установки - 6 шт, ' +
          'наружные блоки - 4 шт, ' +
          'внутренние блоки  - 30 шт, ' +
          'вытяжные вентиляторы - 8 шт, ' +
          'мощность котельного оборудования 550 кВт'
  }, {
      src  : ofbChakb,
      title: 'Филиал банка ЧАКБ ORIENT FINANS BANK',
      desc : 'Общая мощность по холоду - 465 кВт ' +
          'Установлено: ' +
          'наружные блоки - 4 шт, ' +
          'напольные конвектора - 76 шт, ' +
          'вытяжные вентиляторы - 10 шт, ' +
          'мощность котельного оборудования - 450 кВт'
  }, {
      src  : holidayInn,
      title: 'Гостиница HOLIDAY Inn',
      desc : 'Общая мощность по холоду - 2340 кВт ' +
          'Система VRF - 180 кВт ' +
          'Установлено: ' +
          'холодильные машины (чиллеры) - 18 шт, ' +
          'приточные установки  - 20 шт, ' +
          'канальные и кассетные фанкоилы - 487 шт, ' +
          'наружные блоки - 8 шт, ' +
          'внутренние блоки - 50 шт, ' +
          'вытяжные вентиляторы - 30 шт, ' +
          'мощность котельного оборудования 3800 кВт'
  }, {
      src  : makroC1,
      title: 'Ц1 Макро',
      desc : 'Общая мощность по холоду - 800 кВт ' +
          'Установлено: ' +
          'приточные установки  - 19 шт, ' +
          'вытяжные вентиляторы - 22 шт, ' +
          'мощность котельного оборудования 1650 кВт, ' +
          'наружные блоки - 10 шт, ' +
          'внутренние блоки - 95 шт, ' +
          'внутрипольные конвектора - 79 шт'
  }, {
      src  : orient,
      title: 'Бизнес центр «ORIENT»',
      desc : 'Общая мощность по холоду - 2000 кВт ' +
          'Установлено: ' +
          'наружные блоки - 50 шт, ' +
          'приточные установки - 3 шт, ' +
          'внутренние блоки - 200 шт, ' +
          'компрессорно-конденсаторный блок (установка ККБ), ' +
          'вытяжные вентиляторы - 13 шт, ' +
          'системы подпора Jet Fan - 12 шт, ' +
          'мощность котельного оборудования - 1500 кВт'
  }, {
      src  : prosecutorOffice,
      title: 'Зал заседаний для прокуратуры г.Ташкента',
      desc : 'Система VRF - 156 кВт, ' +
          'внутренние блоки - 20 шт, ' +
          'наружние блоки - 6 шт, ' +
          'вытяжные вентиляторы - 10 шт'
  }, {
      src  : unicef,
      title: 'ЮНИСЕФ холодовой склад вакцин',
      desc : 'Общая мощность по холоду - 260 кВт ' +
          'Установлено: ' +
          'приточные установки - 2 шт, ' +
          'компрессорно-конденсаторный блок (установка ККБ) - 3 (100 кВт), ' +
          'кондиционеры для холодильных камер - 16 шт (160 кВт)'
  }, {
      src  : foodMall,
      title: 'Food Mall Tashkent',
      desc : 'Общая мощность по холоду 235 кВт ' +
          'Установлено: ' +
          'холодильные машины (ВРФ) - 3 шт, ' +
          'приточные установки - 5 шт, ' +
          'кассетные блоки - 20 шт, ' +
          'вытяжные вентиляторы - 12 шт'
  }, {
      src  : amirsoy,
      title: 'Амирсай 2290',
      desc : 'Общая мощность по холоду - 115 кВт ' +
          'Система VRF - 67  кВт ' +
          'Установлено: ' +
          'Приточные установки - 2 шт, ' +
          'Наружные блоки VRF - 2 шт, ' +
          'Внутренние блоки VRF - 12 шт, ' +
          'Вытяжные вентиляторы - 5 шт'
  }, {
      src  : crowne,
      title: 'Crowne Plaza',
      desc : 'Общая мощность по холоду - 1300 кВт ' +
          'Система VRF - 350 кВт ' +
          'Установлено: ' +
          'Приточные установки - 25 шт, ' +
          'Канальные и кассетные фанкоилы - 370 шт, ' +
          'Наружные блоки VRF - 6 шт, ' +
          'Внутренние блоки VRF - 50 шт, ' +
          'Вытяжные вентиляторы - 70 шт, ' +
          'Мощность котельного оборудования 1800 кВт '
  }, {
      src   : regalStayResidences,
      title : 'Regal Stay Residences',
      desc  : 'Общая мощность по холоду - 180 кВт ' +
          'Общая мощность по теплу - 200 кВт ' +
          'Установлено: ' +
          'Канальный блоки 3,6-14 штук, ' +
          'Канальный блоки 4,5-4 штуки, ' +
          'Канальный блоки 5,6-2 штуки, ' +
          'Канальный блоки 11,5-6 штуки. ' +
          'Free Mach: ' +
          'Наружный блок 8 кВт -2 штук, ' +
          'Настенный блоки 12-6 штук'
  } ]
};