import React from "react";
import {
  Box,
  Flex,
  Wrap,
  WrapItem,
  Link,
  Center,
  List,
  ListItem,
  Image,
  Icon,
  Heading,
  Text
} from "@chakra-ui/react";

import logo from "../../assets/images/logo.png";

import {
  BiMobile
} from "react-icons/bi";

import Move from "../ui/IconAnimations";

const Header = ( ( props : any ) => {

  const onClick = (e: any) => {
    e.preventDefault();

    const el = document.getElementById(e.target.attributes.href.value.slice(1));

    if (el) {
      el.scrollIntoView({behavior:"smooth"});
    }
  };

  return ( <Flex justify="space-between" wrap="wrap" align="left" m="0 auto" w="100%" p={3} direction={{ xl: "row", xs: "row", lg: "row", md: "row", sm: "column" }}>

        <Box
            float="left"
            w={{ xxl: "70%", xl: "70%", xs: "60%", lg: "60%", md: "55%", sm: "100%" }}
            textAlign="left"
        >

          <Box
              m="0px auto;"
              w={{ xl: "250px", xs: "250px", lg: "250px", md: "250px", sm: "100%" }}
              textAlign={{ xl: "left", xs: "left", lg: "left", md: "left", sm: "center" }}
              float="left"
          >

            <Image display="initial" src={ logo } w="auto" />

          </Box>

        </Box>

        <Center
            w={{ xxl: "30%", xl: "30%", xs: "40%", lg: "40%", md: "45%", sm: "100%" }}
        >

          <Center
              w="20%"
              float="left"
              h="40px"
          >

            <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >

              <Icon
                  as={BiMobile}
                  w="40px"
                  h="40px"
                  color="#e04e12"
                  borderRadius="50%"
                  p="5px"
                  bg="#00000054"
              />

            </Move>

          </Center>

          <Box
              m={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "25px 0px 0px;", sm: "25px 0px 0px;" }}
          >

            <Heading
                as="h4"
                fontSize="30px"
            >
              +998 (55) 500-9900
            </Heading>

            <Link
                href="#feedback"
                onClick={ onClick }
            >
              Заказать обратный звонок
            </Link>

          </Box>

        </Center>

   </Flex>
  );

} );

export default Header;
