import React from "react";
import { Icon } from "@chakra-ui/react"

import {
  AiFillFire
} from "react-icons/ai";

import {
  Flex,
  VStack,
  Image,
  Heading,
  Box,
  SimpleGrid,
  Link,
  Button
} from "@chakra-ui/react"

import Move from "../ui/IconAnimations";

import { projects } from "../../store/projects";

import { SRLWrapper } from "simple-react-lightbox";

export default function Projects() {

    const onClick = (e: any) => {

        e.preventDefault();

        const el = document.getElementById( e.target.parentElement.attributes.href.value.slice(1) );

        if ( el ) {

            el.scrollIntoView({
                behavior:"smooth"
            } );

        }

    };

  return (<>

        <Flex
          id="projects"
          align="center"
          justify={{ base: "center", md: "space-around", xl: "space-between" }}
          direction={{ base: "column-reverse", md: "row" }}
          bg="URL('./images/aboutUs.jpg')"
          bgRepeat="no-repeat"
          w="100%"
          bgSize="cover"
          p={{ xxl: "25px 0px", xl: "25px 50px", xs: "25px 50px", lg: "50px", md: "50px", sm: "50px" }}
        >

              <VStack
                maxW={{ xl: "1200px" }}
                w="100%"
                m="0px auto"
                pos="relative"
                textAlign="center"
              >

                  <Box
                    w="100%"
                    pos="relative"
                    m="50px 0px"
                  >

                      <Heading
                        as="h2"
                        textTransform="uppercase"
                        fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
                      >

                            <Icon as={AiFillFire}
                                  bg="#e04e12"
                                  color="#ffffff"
                                  borderRadius="50%"
                                  border="1px solid #ffffff"
                                  p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                                  m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
                            />
                            Наши проекты

                      </Heading>

                  </Box>

                  <Box
                    w="100%"
                    pos="relative"
                  >

                      <SimpleGrid
                          columns={{ xxl: 5, xl: 5, xs: 5, lg: 5, md: 1, sm: 1 }}
                          spacing={{ xxl: "15px", xl: "15px", xs: "15px", lg: "15px", md: "15px", sm: "15px" }}
                      >

                        { projects.catalogs.map( ( catalog: any, key: any ) => (

                            <Box
                                align="center"
                                textAlign="center"
                                key={key}
                            >

                                  <Move
                                      y={20}
                                  >

                                        <Icon
                                            as={catalog.icon}
                                            fontSize="45px"
                                            mb="15px"
                                            fill="#e04e12"
                                        />

                                  </Move>

                              <Heading
                                  as="h6"
                                  fontSize="14px"
                                  color="#ffffff"
                                  lineHeight="1.5"
                                  m="0px auto"
                              >
                                    <div dangerouslySetInnerHTML={{ __html: catalog.name}} />
                              </Heading>

                            </Box>

                        ) ) }

                      </SimpleGrid>

                  </Box>

                  <Box
                    w="100%"
                    pos="relative"
                    className="project-list"
                  >

                      <SRLWrapper options={{
                          settings: {
                              overlayColor: "#0493d7cc", /*#0493d782*/
                          },
                          buttons: {
                              backgroundColor: "#0093d7",
                              iconColor: "#ffffff",
                          },
                          caption: {
                              captionColor: "#ffffff",
                              showCaption: true
                          }
                      }} >

                            <SimpleGrid
                                columns={{ xxl: 4, xl: 4, xs: 4, lg: 4, md: 1, sm: 1 }}
                                spacing={{ xxl: "15px", xl: "15px", xs: "15px", lg: "15px", md: "25px", sm: "25px" }}
                                m="50px 0px !important"
                            >

                                { projects.items.map( ( project: any, key: any ) => (

                                    <Box
                                        key={key}
                                        className="project"
                                    >

                                        <Link to={project.src} className="element_with_overlay">

                                            <Image
                                                src={project.src}
                                                srl_gallery_image="true"
                                                alt={project.desc}
                                            />

                                        </Link>

                                        <Heading
                                            as="h4"
                                            className="project-name"
                                        >
                                            {project.title}
                                        </Heading>

                                    </Box>

                                ) ) }

                           </SimpleGrid>

                      </SRLWrapper>

                      <Link
                          href="#feedback"
                          textDecoration="none !important"
                          color="#ffffff !important"
                          onClick={onClick}
                      >

                            <Button
                                textTransform="uppercase"
                                bg="none"
                                border="1px solid #0094d8"
                                p="15px 35px"
                                h="auto !important"
                                fontSize="14px"
                                className="service-button"
                                _hover= {{
                                  bg: 'none'
                                }}
                            >
                                просчитать ваш проект
                            </Button>

                      </Link>

              </Box>

        </VStack>

    </Flex>

</> );
}
