import React from "react";

import { Icon } from "@chakra-ui/react"
import {
  AiFillFacebook, AiFillInstagram
} from "react-icons/ai";

import {
  Flex,
  Heading,
  Box,
  Badge,
  Link,
  List,
  ListItem,
  Text
} from "@chakra-ui/react";

import GMapLocationSelector from '../ui/Maps';

import Move from "../ui/IconAnimations";

export default function Contacts() {


  return (
    <>
    <Flex
      id="contacts"
      w="100%"
      pos="relative"
    >

      <Box
        pos="absolute"
        zIndex={9999}
        maxW={{ xl: "1200px" }}
        top={0}
        left={{ xxl: "50%", xl: "50%" }}
        m={{ xxl: "50px 0px 0px -580px", xl: "50px 0px 0px -530px", xs: "50px 0px 0px 75px", lg: "50px 0px 0px 20px", md: "85px 25% 0px", sm: "85px 25px 0px" }}
      >

        <List
          shadow="md"
          borderWidth="1px"
          borderRadius="15px"
          bg="#005c89db"
          textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "left", md: "center", sm: "center" }}
          p={25}
          w={{ xxl: "400px", xl: "400px", xs: "400px", lg: "400px", md: "100%", sm: "100%" }}
          m={{ xxl: "30px 0px 0px", xl: "30px 0px 0px", xs: "30px 0px 0px", lg: "30px 0px 0px", md: "0px auto" }}
        >

          <ListItem
            p="10px 0px"
          >

            <Heading
             as="h4"
             fontSize={20}
             textTransform="uppercase"
            >
              Наши контакты:
            </Heading>

          </ListItem>

          <ListItem
            p="10px 0px">
            <Link
              href="tel: +998555009900" //+79778019900 +7977-801-9900
              color="#ff8655 !important"
              fontSize={20}
              fontWeight="bold"
            >
              +99855-500-9900
            </Link>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#000000"
                p="5px 0px"
                bg="none"
              >
                АДРЕС:
              </Badge>
              г. Ташкент, ул. Уйсозлар 75
            </Text>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#000000"
                p="5px 0px"
                bg="none"
              >
                ЭЛЕКТРОННАЯ ПОЧТА:
              </Badge>
              info@everestbuildinggroup.uz
            </Text>
          </ListItem>

          <ListItem p="10px 0px">

            <Text lineHeight={1.5} >

              <Badge
                w="100%"
                color="#000000"
                p="5px 0px"
                bg="none"
              >
                ГРАФИК РАБОТЫ:
              </Badge>
              Пн-Пт с 09:00 до 19:00

            </Text>

          </ListItem>

          <ListItem p="10px 0px">

            <Link href="https://www.facebook.com/everestgroup.uz/" target="__blank">

              <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                <Icon
                  as={AiFillFacebook}
                  color="#3d589b"
                  w="35px"
                  h="35px"
                />
              </Move>

            </Link>

            <Link href="https://instagram.com/everestbuildinggroup/" target="__blank">

              <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                <Icon
                  as={AiFillInstagram}
                  color="#c22984"
                  w="35px"
                  h="35px"
                />
              </Move>

            </Link>

          </ListItem>

        </List>

      </Box>

      <GMapLocationSelector
        lat={41.2603411}
        lng={69.3416607}
      />

    </Flex>
    </>
  );
}