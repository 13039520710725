import { ReactComponent as Experience } from "assets/icons/experience.svg";
import { ReactComponent as Leading } from "assets/icons/leading.svg";
import { ReactComponent as Licence } from "assets/icons/licence.svg";
import { ReactComponent as Revenue } from "assets/icons/revenue.svg";

export const sliders = [ {
  icon: Experience,
  title: '8 лет опыта в <br /> проектировании <br /> объектов',
}, {
  icon: Leading,
  title: '№ 1 на рынке <br /> монтажа <br /> оборудования',
}, {
  icon: Licence,
  title: 'Лицензия на все <br /> виды монтажных <br /> и проектных работ',
}, {
  icon: Revenue,
  title: 'Выгодные цены <br /> на оборудование <br /> и услуги',
} ];