import React, { useState } from "react";
import { Icon } from "@chakra-ui/react"

import { useSpring, animated, config } from 'react-spring'

import {
    BiUserCheck
} from "react-icons/bi";

import {
  Flex,
  VStack,
  Stack,
  Heading,
  Center,
  Box,
  SimpleGrid,
  GridItem,
  Wrap,
  WrapItem,
  Badge,
  Image,
  Text
} from "@chakra-ui/react"

import Move from "../ui/IconAnimations";

import { team } from "../../store/team";

const History = ( { year, desc, ...rest } : any  ) => {

  return (

      <WrapItem
        p="15px 0px"
        display={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "block", sm: "block" }}
        textAlign={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "center", sm: "center" }}
      >

          <Center
              fontSize="70px"
              h="80px"
              w={{ xxl: "20%", xl: "20%", xs: "20%", lg: "20%", md: "100%", sm: "100%" }}
              fontWeight="bold"
          >
            { year }
          </Center>

          <Box
              w={{ xxl: "80%", xl: "80%", xs: "80%", lg: "80%", md: "100%", sm: "100%" }}
          >
              <Text>
                {desc}
              </Text>
          </Box>

      </WrapItem>
  )
};

const Teams = ( { avatar, name, position, step, ...rest } : any  ) => {

  const [hover, setHover] = useState(false);
  const hoverState = useSpring({
    transform: hover
        ? 'translate3d(0px, -12px, 0px) scale(1.03)'
        : 'translate3d(0px, 0px, 0px) scale(1)',
    config: config.wobbly
  });

  return (

      <Box
          align="center"
          margin="0px auto"
          pos="relative"
          {...rest}
          _after={ name !== '' && step == 0
              ? {
                content: '""',
                pos: "absolute",
                top: "1px",
                bg: "#000000ab",
                borderRadius: "50%",
                w: { xxl: '232px', xl: '232px', xs: '221px', lg: '221px', md: '221px', sm: '221px' },
                fontSize: "12px",
                p: "50px  0px;",
                zIndex: "0",
                left: "50%",
                ml: "-103px",
                h: { xxl: '219px', xl: '219px', xs: '202px', lg: '202px', md: '202px', sm: '202px' },
                border:"2px solid #0094d8",
                display: { xxl: 'block', xl: 'block', xs: 'none', lg: 'none', md: 'none', sm: 'none' }
            }
            : {}
          }
      >

        <animated.div
            style={{
              width: '100%',
              zIndex: 1,
              position: 'relative',
              ...hoverState
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >

          <Image
              src={avatar}
              w="auto"
          />

        </animated.div>

        { name !== '' && step === 0 &&

              <Box
                  pos="absolute"
                  right="80px"
                  w="300px"
                  top={{ xxl: '25px', xl: '25px', xs: '20px', lg: '20px', md: '20px', sm: '20px' } }
                  display={{ xxl: 'block', xl: 'block', xs: 'none', lg: 'none', md: 'none', sm: 'none' } }
                  _before={{
                    content:'""',
                    bg:`URL(./images/ceo-speach-pointer.png) no-repeat`,
                    bgSize:'100px',
                    w: '100%',
                    h: '500px',
                    pos: 'absolute',
                    left:'-92px',
                    top: '45px'
                  }}
              >

                  <Text
                      fontSize="12px"
                  >
                      «Настоящая ответственность бывает только личной, Человек краснеет один».
                      <br />
                      Когда я задумывал мою компанию, то хотел создать идеальное сочетание качества и профессионализма, чтобы наши клиенты видели это и доверяли нам еще больше.
                  </Text>

              </Box>

        }

        <Heading
            as="h6"
            fontSize="16px"
            lineHeight="1.5"
            text-transform="uppercase"
            m="0px auto"
        >
              {name}
        </Heading>

        <Badge
              bg={{ xxl: '#00000096', xl: '#00000096', xs: '#00000096', lg: 'none', md: 'none', sm: 'none' }}
              color="#e04e12"
              textTransform="capitalize"
              p={{ xxl: '2px 10px', xl: '2px 10px', xs: '2px 10px', lg: '0px', md: '0px', sm: '0px' }}
              borderRadius="15px"
        >
            {position}
        </Badge>

        { name !== '' && step == 0 &&

              <Text
                  fontSize="12px"
                  display={{ xxl: 'none', xl: 'none', xs: 'none', lg: 'block', md: 'block', sm: 'block' }}
              >
                  «Настоящая ответственность бывает только личной, Человек краснеет один».
                  <br />
                  Когда я задумывал мою компанию, то хотел создать идеальное сочетание качества и профессионализма, чтобы наши клиенты видели это и доверяли нам еще больше.
              </Text>

          }

    </Box>
  )

};

export default function Team() {

  return (
    <>

        <Flex
            id="our-team"
            align="center"
            justify={{ base: "center", md: "space-around", xl: "space-between" }}
            direction={{ base: "column-reverse", md: "row" }}
            bg="URL('./images/mountain-bg.png')"
            bgPosition="top center"
            bgRepeat="no-repeat"
            w="100%"
            bgSize="cover"
            p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "50px", md: "50px", sm: "50px" }}
        >

          <VStack
              maxW={{ xl: "1200px" }}
              w="100%"
              m="0px auto"
              pos="relative"
              textAlign="center"
          >

              <Box
                  w="100%"
                  pos="relative"
                  m="-50px 0px 20px"
              >

                  <Heading
                      as="h2"
                      textTransform="uppercase"
                      fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
                  >
                      <Icon as={BiUserCheck}
                            bg="#e04e12"
                            color="#ffffff"
                            borderRadius="50%"
                            border="1px solid #ffffff"
                            p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                            m={ { xxl: "0px 5px 8px 0px", xl: "0px 5px 8px 0px", xs: "0px 5px 8px 0px", lg: "0px 5px 8px 0px", md: "0px 5px 8px 0px", sm: "0px 5px 8px 0px" } }
                      />
                      Наша команда
                  </Heading>

              </Box>

              <Box
                  w="100%"
                  pos="relative"
                  mb={{xxl: "20px" }}
              >

                  <SimpleGrid
                      columns={{ xxl: 2, xl: 2, xs: 2, lg: 1, md: 1, sm: 1 }}
                      spacing={{ xxl: "50px", xl: "50px", xs: "50px", lg: "175px", md: "25px", sm: "25px" }}
                      textAlign="center"
                      align="center"
                  >

                      { team.main.map( ( lead: any, step: any ) => (

                          <GridItem colSpan={step == 0 ? {
                              xxl: 2, xl: 2, xs: 2, lg: 1, md: 1, sm: 1
                          } : 1 }>

                              <Teams
                                  avatar={lead.avatar}
                                  name={lead.name}
                                  position={lead.position}
                                  step={step}
                                  key={step}
                              />

                          </GridItem>

                      ) ) }

                  </SimpleGrid>

              </Box>

          </VStack>

      </Flex>

        <Flex
            id="history"
            w="100%"
            bg="URL('./images/full-team.jpg')"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPosition="center"
            pos="relative"
            p={{ xxl: "186px 0px 216px", xl: "186px 0px 216px", xs: "186px 0px 216px", lg: "15px 0px 15px", md: "15px 0px 15px", sm: "15px 0px 15px" }}
            overflow="hidden"
            _before={{
                content: '""',
                bg: 'URL("./images/logo-big.png") no-repeat',
                bgSize: 'contain',
                pos: 'absolute',
                left: '-13%',
                w: '100%',
                h: '500px',
                bottom: '60px',
                opacity: '0.2',
                zIndex:"1"
            }}
            _after={{
                content: '""',
                bg: 'URL("./images/logo-big.png") no-repeat',
                bgSize: 'contain',
                pos: 'absolute',
                right: '-88%',
                w: '100%',
                h: '500px',
                bottom: '60px',
                opacity: '0.2',
                zIndex:"1"
            }}
        >

         <Box
             pos="absolute"
             left="0"
             right="0"
             top="0"
             bottom="0"
             bg="#00375b8c"
         />

        <Wrap
            p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "15px 0px", sm: "15px 0px" }}
            maxW={{ xl: "1200px" }}
            w="100%"
            align="center"
            margin="0px auto"
            zIndex="1"
        >

            <History
                year="2013"
                desc="Наша компания представлена на рынке Республики Узбекистан с 2013 года. С самого основания мы специализируемся на проектировании и реализации систем кондиционирования, вентиляции, отопления, водоснабжения, канализации, а также построении автономных котельных станций."
            />

            <History
                year="2016"
                desc="К 2016 году мы выросли в целую группу компаний, провели масштабный ребрендинг и с тех пор работаем как Everest Building Group. Тем не менее принципы, заложенные в самом начале, остаются неизменными – внедрение передовых технологий и экологически безопасных систем при неизменно высоком уровне качества и надежности для обеспечения максимального комфорта наших клиентов."
            />

          <History
              year="2020"
              desc="За время своего существования компания успешно реализовала целый ряд обширных проектов, включая объекты городской инфраструктуры, производства, торговли и сферы HoReCa. В 2020 году руководством компании было принято решение о начале предоставления услуг частным клиентам, чтобы дать возможность каждому жителю Узбекистана установить в своем доме передовые климатические системы."
          />

        </Wrap>

        </Flex>

    </>
  );
}
