import React from "react";
import ReactDOM from "react-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ChakraProvider } from "@chakra-ui/react";
import SimpleReactLightbox from "simple-react-lightbox";

import everestGroup from "./utils/Theme";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={everestGroup}>
        <SimpleReactLightbox>
            <App />
        </SimpleReactLightbox>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById(
      "root"
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
