import team from '../assets/images/anniversary/anniversary-team.jpg';
import logo from '../assets/images/anniversary/anniversary-logo.jpg';
import teamSand from '../assets/images/anniversary/anniversary-team-sand.jpg';


export const anniversary_team = [ {
  thumbnail: team
}, {
  thumbnail: logo
}, {
  thumbnail: teamSand
} ];